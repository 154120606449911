@import-normalize; /* bring in normalize.css styles */

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333333;
  font-size: 16px;
  line-height: 22px;
  word-break: break-word;
  word-wrap: break-word;
  font-family: Inter, -apple-system, sans-serif;
  font-feature-settings: 'tnum' on, 'lnum' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, p {
  margin: 0;
}

a, button, select {
  cursor: pointer;
}

a {
  color: #333333;
  text-decoration: none;
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
}
